const defaultEnv = 'dev'; //development,staging,production

const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
const eventer = window[eventMethod];
const messageEvent = 'attachEvent' == eventMethod ? 'onmessage' : 'message';

function encodeQS(object) {
  function reducer(obj, parentPrefix = null) {
    return function (prev, key) {
      const val = obj[key];
      key = encodeURIComponent(key);
      const prefix = parentPrefix ? `${parentPrefix}[${key}]` : key;

      if (val == null || typeof val === 'function') {
        prev.push(`${prefix}=`);
        return prev;
      }

      if (typeof val === 'boolean') {
        prev.push(`${prefix}=${val.toString().toUpperCase()}`);
        return prev;
      }

      if (['number', 'string'].includes(typeof val)) {
        prev.push(`${prefix}=${encodeURIComponent(val)}`);
        return prev;
      }

      prev.push(Object.keys(val).reduce(reducer(val, prefix), []).join('&'));
      return prev;
    };
  }
  return Object.keys(object).reduce(reducer(object), []).join('&');
}

function decodeQS(querystring) {
  function parseValue(value) {
    if (value === 'TRUE') return true;
    if (value === 'FALSE') return false;
    // return isNaN(Number(value)) ? value : Number(value);
    return value;
  }

  function dec(list, isArray = false) {
    let obj = isArray ? [] : {};

    let recs = list.filter((item) => {
      if (item.keys.length > 1) return true;
      obj[item.keys[0]] = parseValue(item.value);
    });

    let attrs = {};
    recs
      .map((item) => {
        item.key = item.keys.shift();
        attrs[item.key] = [];
        return item;
      })
      .forEach((item) => attrs[item.key].push(item));

    Object.keys(attrs).forEach((attr) => {
      let nextKey = attrs[attr][0].keys[0];
      obj[attr] = dec(attrs[attr], typeof nextKey === 'number');
    });

    return obj;
  }

  return dec(
    querystring
      .split('&')
      .map((item) => item.split('=').map((x) => decodeURIComponent(x)))
      .map((item) => {
        return {
          keys: item[0]
            .split(/[\[\]]/g)
            .filter((n) => n)
            .map((key) => (isNaN(Number(key)) ? key : Number(key))),
          value: item[1]
        };
      })
  );
}

function buildUrl(url, parameters) {
  function cleanUpParams(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }

      if (typeof obj[propName] === 'object') {
        for (var nPropName in obj[propName]) {
          if (obj[propName][nPropName] === null || obj[propName][nPropName] === undefined) {
            delete obj[propName][nPropName];
          }
        }
      }
    }
    return obj;
  }

  function cleanUpUrl(urlToClean) {
    let cleanedUrl,
      extractedParams = {};

    var hasQs = urlToClean.split('?');
    if (hasQs.length > 1) {
      cleanedUrl = hasQs[0];
      hasQs[1].split('&').forEach((params) => {
        var param = params.split('=');
        extractedParams[param[0]] = param[1];
      });
    }

    return {
      cleanedUrl,
      extractedParams
    };
  }

  var { cleanedUrl, extractedParams } = cleanUpUrl(url);
  if (cleanedUrl) {
    url = cleanedUrl;
  }
  if (extractedParams) {
    parameters = {
      ...parameters,
      ...extractedParams
    };
  }

  var qs = encodeQS(cleanUpParams(parameters));
  if (qs.length > 0) {
    // qs = qs.substring(0, qs.length - 1); //chop off last "&"
    url = url + '?' + qs;
  }
  return url;
}

const checkUrlValidity = (url) => {
  const reg = new RegExp(
    /^http(s?):\/\/((\w+.)?\w+.\w+\w.+|((2[0-5]{2}|1[0-9]{2}|[0-9]{1,2}).){3}(2[0-5]{3}|1[0-9]{3}|[0-9]{1,2})(:\d{1,5})?)(((\/)(.+)?)?)$/gm
  );
  return reg.test(url);
};

const consoleMessageHandler = (type, message) => {
  const logType = console[type];
  logType('Lyzi button SDK:', message);
};

const exceptionHandler = (error) => {
  throw new Error(`Lyzi button sdk: ${error}`);
};

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
}

var getUrl = (env) => {
  const localButtonUrl = 'http://localhost:4200';
  const devButtonUrl = 'https://pay-dev.lyzi.io';
  const stagingButtonUrl = 'https://pay-staging.lyzi.io';
  const prodButtonUrl = 'https://pay.lyzi.io';

  const localApiUrl = 'http://localhost:3000/api/next';
  const devApiUrl = 'https://api-dev.lyzi.fr/api/next';
  const stagingApiUrl = 'https://api-staging.lyzi.fr/api/next';
  const prodApiUrl = 'https://api.lyzi.fr/api/next';

  switch (env) {
    case 'dev':
    case 'develop':
    case 'development':
      return { button: devButtonUrl, service: devApiUrl };
    case 'sta':
    case 'stg':
    case 'stag':
    case 'staging':
      return { button: stagingButtonUrl, service: stagingApiUrl };
    case 'prd':
    case 'prod':
    case 'production':
      return { button: prodButtonUrl, service: prodApiUrl };
    default:
      return { button: localButtonUrl, service: localApiUrl };
  }
};

class lyziBuyButtonSdk {
  constructor(
    buttonId,
    buttonName,
    orderRef,
    price,
    goods,
    callbackUrl,
    returnUrl,
    cancelUrl,
    currency = 'EUR',
    env = defaultEnv
  ) {
    // if (!buttonId) exceptionHandler('buttonId is required');
    if (!buttonId) consoleMessageHandler('warn', 'buttonId is not defined, this will probably cause a crash');
    if (goods) {
      if (!goods.goodsName) exceptionHandler('goodsName is required');
      if (!goods.goodsType) exceptionHandler('goodsType is required');
      if (!goods.goodsCategory) exceptionHandler('goodsCategory is required');
    }
    if (callbackUrl) {
      const isValidUrl = checkUrlValidity(callbackUrl);
      if (!isValidUrl) {
        exceptionHandler("please re-check your callbackUrl and make sure it's a valid url");
      }
    }
    if (returnUrl) {
      const isValidUrl = checkUrlValidity(returnUrl);
      if (!isValidUrl) {
        exceptionHandler("please re-check your returnUrl and make sure it's a valid url");
      }
    }
    if (cancelUrl) {
      const isValidUrl = checkUrlValidity(cancelUrl);
      if (!isValidUrl) {
        exceptionHandler("please re-check your cancelUrl and make sure it's a valid url");
      }
    }

    if (buttonId) {
      const { button, service } = getUrl(env);
      this.config = {
        buttonId: buttonId,
        orderRef: orderRef,
        price: price,
        buttonName: buttonName,
        goods: goods,
        callbackUrl: callbackUrl,
        returnUrl: returnUrl,
        cancelUrl: cancelUrl,
        currency: currency,
        env: env,
        appBaseUrl: button,
        appServiceUrl: service
      };
      eventer(messageEvent, this.messageListener.bind(this));
    }
  }

  messageListener(e) {
    let t = e.data;
    if (Object(t).hasOwnProperty('lyziToken')) this.lyziToken = t.lyziToken ?? undefined;
    if (Object(t).hasOwnProperty('sessionId')) this.sessionId = t.sessionId ?? undefined;
    if (Object(t).hasOwnProperty('conversionCode')) {
      this.conversionCode = t.conversionCode ?? undefined;
      if (this.conversionCode) {
        consoleMessageHandler('info', 'conversion code generated');
      }
    }
    if (Object(t).hasOwnProperty('paymentState')) {
      this.paymentState = t.paymentState ?? undefined;
      // this.handlePaymentStateChange(this.paymentState);
    }
  }

  set config(conf) {
    window.sessionStorage.setItem('lyziButtonSDKConfig', JSON.stringify(conf));
  }

  get config() {
    return JSON.parse(window.sessionStorage.getItem('lyziButtonSDKConfig'));
  }

  set lyziToken(token) {
    window.sessionStorage.setItem('lyziButtonSDKServiceAuthToken', token);
  }

  get lyziToken() {
    return window.sessionStorage.getItem('lyziButtonSDKServiceAuthToken');
  }

  set sessionId(token) {
    window.sessionStorage.setItem('lyziPaymentSession', token);
  }

  get sessionId() {
    return window.sessionStorage.getItem('lyziPaymentSession');
  }

  set conversionCode(value) {
    window.sessionStorage.setItem('lyziPaymentCode', value);
  }

  get conversionCode() {
    return window.sessionStorage.getItem('lyziPaymentCode');
  }

  set paymentState(value) {
    window.sessionStorage.setItem('lyziPaymentState', JSON.stringify(value));
  }

  get paymentState() {
    return JSON.parse(window.sessionStorage.getItem('lyziPaymentState'));
  }

  get buttonUrl() {
    const params = {
      id: this.config.buttonId,
      orderRef: this.config.orderRef,
      price: this.config.price,
      currency: this.config.currency,
      callbackUrl: this.config.callbackUrl,
      goods: this.config.goods,
      buttonName: this.config.buttonName,
      returnUrl: this.config.returnUrl,
      cancelUrl: this.config.cancelUrl
    };

    return buildUrl(`${this.config.appBaseUrl}/buy-button/landing`, params);
  }

  set buttonUrl(url) {
    this.buttonUrl = url;
  }

  get htmlSnippet() {
    const modalJsSnippet =
      '<script type="text/javascript">if(lyziBuyButton){lyziBuyButton.handleModal();}else{console.error("lyzi modal not initialized")}</script>';
    const buttonSnippet = ` <div class=lyzi> <form id=lyzi-form action="${this.buttonUrl}"> <img class=cryptos-icon src=${this.config.appBaseUrl}/assets/buy-button/Coins.svg alt=cryptos> <button class=pay-button> Payer en crypto </button> <div class=powered>Powered By <img class=lyzi-logo src=${this.config.appBaseUrl}/assets/buy-button/lyzi-logo.png alt="lyzi logo"> </div></form> </div>`;
    const cssHrefSnippet = `<link href="${this.config.appBaseUrl}/assets/buy-button/buy-button.css" rel="stylesheet">`;

    return `${cssHrefSnippet} ${buttonSnippet} ${modalJsSnippet}`;
  }

  removeSession() {
    if (!this.lyziToken) exceptionHandler('unauthorized lyzi sdk service');
    if (!this.sessionId) exceptionHandler('session not found');

    var xhr = new XMLHttpRequest();
    var url = this.config.appServiceUrl + '/buy-button/sessions/' + this.sessionId;
    xhr.open('DELETE', url, false);
    xhr.setRequestHeader('Authorization', 'Bearer ' + this.lyziToken);
    xhr.send();
  }

  handlePaymentStateChange() {
    const data = this.paymentState;
    if (data) {
      switch (data?.status) {
        case 'INITIAL':
        case 'PENDING':
        case 'AWAITING':
          break;
        case 'PAID':
          if (this.config.returnUrl) {
            this.sendCallback(this.config.returnUrl, data);
          } else if (this.config.callbackUrl) {
            this.sendCallback(this.config.callbackUrl, data);
          }
          break;
        default:
          if (this.config.cancelUrl) {
            this.sendCallback(this.config.cancelUrl, data);
          } else if (this.config.callbackUrl) {
            this.sendCallback(this.config.callbackUrl, data);
          }
          break;
      }
    }
  }

  sendCallback(url, data = {}) {
    const payload = { ...data };
    delete payload.fees;
    delete payload.returnUrl;
    delete payload.cancelUrl;
    delete payload.cashback;
    delete payload.__v;
    delete payload.confirm.checkoutUrl;
    delete payload.confirm.deeplink;
    delete payload.confirm.universalUrl;
    delete payload.confirm.merchant?.binanceSubMerchantId;
    delete payload.confirm.pos?.manager?.cashbackBalance;
    payload?.refund && delete payload.refund;
    payload?.cashback && delete payload.cashback;

    url = buildUrl(url, payload);
    window.location.href = url;
  }

  handleModal() {
    const lyziLoadingImg =
      'data:image/svg+xml;base64,PHN2ZyBjbGFzcz0ic3ZnLWxvYWRlciIgdmVyc2lvbj0iMS4xIiBpZD0iTDQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMCAwIiB4bWw6c3BhY2U9InByZXNlcnZlIgogICAgdmlld0JveD0iMCA0NCA1MiAxMiI+CiAgICA8Y2lyY2xlIGZpbGw9IiMxZTg2ZWMiIHN0cm9rZT0ibm9uZSIgY3g9IjYiIGN5PSI1MCIgcj0iNiI+CiAgICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9Im9wYWNpdHkiIGR1cj0iMXMiIHZhbHVlcz0iMDsxOzAiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiBiZWdpbj0iMC4xIj48L2FuaW1hdGU+CiAgICA8L2NpcmNsZT4KICAgIDxjaXJjbGUgZmlsbD0iIzFlODZlYyIgc3Ryb2tlPSJub25lIiBjeD0iMjYiIGN5PSI1MCIgcj0iNiI+CiAgICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9Im9wYWNpdHkiIGR1cj0iMXMiIHZhbHVlcz0iMDsxOzAiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiBiZWdpbj0iMC4yIj48L2FuaW1hdGU+CiAgICA8L2NpcmNsZT4KICAgIDxjaXJjbGUgZmlsbD0iIzFlODZlYyIgc3Ryb2tlPSJub25lIiBjeD0iNDYiIGN5PSI1MCIgcj0iNiI+CiAgICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9Im9wYWNpdHkiIGR1cj0iMXMiIHZhbHVlcz0iMDsxOzAiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiBiZWdpbj0iMC4zIj48L2FuaW1hdGU+CiAgICA8L2NpcmNsZT4KICA8L3N2Zz4=';

    const body = document.querySelector('body');

    body.innerHTML +=
      '<div class=lyzi-modal> <div class=lyzi-modal-content><img id="lyzi-img-loading" src="' +
      lyziLoadingImg +
      '"/><iframe id="app-frame"></iframe></div></div>';
    let lyziModal = document.querySelector('.lyzi-modal'),
      lyziAppFrame = document.getElementById('app-frame'),
      lyziForm = document.getElementById('lyzi-form'),
      lyziLoader = document.getElementById('lyzi-img-loading');

    eventer(
      messageEvent,
      function (e) {
        let t = e.data;
        if (Object(t).hasOwnProperty('height') && Object(t).hasOwnProperty('width')) {
          (lyziAppFrame.style.height = t.height + 'px'),
            (lyziAppFrame.style.width = t.width + 'px'),
            (lyziLoader.style.display = 'none'),
            (lyziAppFrame.style.display = 'block');
        }
        if (Object(t).hasOwnProperty('closeModal') == true) {
          lyziModal.style.display = 'none';
        }
      },
      !1
    );
    lyziAppFrame.onload = function (e) {
      lyziLoader.style.width = '200px';
      lyziLoader.style.margin = '0 auto';
    };
    lyziForm.onsubmit = function (e) {
      e.preventDefault();
      var os = getMobileOperatingSystem();
      console.log('os', os);

      if (os == 'iOS' || os == 'Android') {
        window.location.href = lyziForm.action;
      } else {
        (lyziAppFrame.src = lyziForm.action),
          (lyziModal.style.display = 'block'),
          (lyziAppFrame.style.display = 'none'),
          (lyziLoader.style.display = 'block');
      }
    };
    window.onclick = function (e) {
      e.target == lyziModal && (lyziModal.style.display = 'none');
    };
  }
}

(function (window) {
  // declare
  let initConfig = {
    env: defaultEnv,
    buttonId: undefined,
    orderRef: null,
    price: null,
    currency: 'EUR',
    buttonAppUrl: undefined,
    callbackUrl: null,
    returnUrl: null,
    cancelUrl: null,
    buttonName: null,
    goods: null
  };

  var initSDK = (options = {}, elementSelector = undefined) => {
    if (!options.buttonId || !options) exceptionHandler('buttonId is required');

    initConfig = {
      ...initConfig,
      ...options
    };

    consoleMessageHandler('log', 'init buy button sdk');
    window.lyziBuyButton = new lyziBuyButtonSdk(
      initConfig.buttonId,
      initConfig.buttonName,
      initConfig.orderRef,
      initConfig.price,
      initConfig.goods,
      initConfig.callbackUrl,
      initConfig.returnUrl,
      initConfig.cancelUrl,
      initConfig.currency,
      initConfig.env
    );

    if (initConfig.buttonAppUrl) {
      var isValidUrl = checkUrlValidity(initConfig.buttonAppUrl);
      if (!isValidUrl) {
        exceptionHandler("please re-check your buttonAppUrl and make sure it's a valid url");
      }

      window.lyziBuyButton.buttonUrl = initConfig.buttonAppUrl;
    }

    if (elementSelector) {
      var snip = window.lyziBuyButton.htmlSnippet;
      var html = document.createRange().createContextualFragment(snip);
      if (elementSelector.tagName == 'BUTTON' || elementSelector.tagName == 'A') {
        var newTag = document.createRange().createContextualFragment(`<div id="${elementSelector.id}"></div>`);
        newTag.append(html);
        elementSelector.replaceWith(newTag);
      } else {
        elementSelector.append(html);
      }
    }
  };

  // sdk prototypes
  lyziBuyButtonSdk.prototype.init = initSDK;
  lyziBuyButtonSdk.prototype.encodeQS = encodeQS;
  lyziBuyButtonSdk.prototype.decodeQS = decodeQS;
  lyziBuyButtonSdk.prototype.buildUrl = buildUrl;
  lyziBuyButtonSdk.prototype.checkUrlValidity = checkUrlValidity;

  // define namespace
  window.lyziBuyButton = new lyziBuyButtonSdk();
})(window, undefined);
